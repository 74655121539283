// Posts and pages
//
// Each page is wrapped in `.page` and is only used on the page layout.

.page {
  margin-bottom: 3em;
  overflow: auto;

  li {
    list-style-position: inside;
  }

  // Meta data line below title
  .date {
    display: block;
    margin-top: -.5rem;
    margin-bottom: 1rem;
    color: #9a9a9a;
  }

  .title,
  .title a {
    color: var(--body-color);
  }

  .title {
    margin-top: 0;
    clear: both;
  }
}